import { ToastUtility } from '@syncfusion/ej2-notifications';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ComboBoxComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchClassPropertiesService, fetchLookupData, filePropertyService, saveClassPropertiesService } from '../../apis/fileService';
import { FileClassProperty, FileClassRequest, LookupDataRequest } from '../../models/FileClassProperty';
import { FileDetailsObject } from '../../models/FileDetails';
import { FolderItemClassResponse } from '../../models/FileItemClasses';
import { FileSession } from '../../models/FileSession';
import "../FileManager/FileProperties.scss";

interface Props {
    FileDetailsObject: FileDetailsObject;
    FolderItemClassResponse?: FolderItemClassResponse;
    FileSession: FileSession;
    onRefreshFunction: () => void;
}

export default function FileProperties({ FileDetailsObject, FolderItemClassResponse, FileSession, onRefreshFunction }: Props) {
    const [selectedDocumentClass, setSelectedDocumentClass] = useState<string | null>(null);
    const [dynamicProperties, setDynamicProperties] = useState<Record<string, string>>({});
    const [registerChange, doRegisterChange] = useState(0);
    const [folderItemClassResponse, setFolderItemClassResponse] = useState<FolderItemClassResponse | undefined>(FolderItemClassResponse);
    const [fetchClasses, doFetchClasses] = useState(0);
    const [lookupData, setLookupData] = useState<Record<string, Array<{ id: string; name: string }>>>({});
    const selectedDocumentClassRef = useRef<string | null>(selectedDocumentClass);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const fetchLookupDataForProperty = useCallback(async (property: any) => {
        if (property.m_datatype === 'Lookup' && property.query) {
            if (lookupData[property.o_propname]) {
                return; // Skip fetch if data already exists
            }

            const lookupDataRequest: LookupDataRequest = { query: property.query };
            try {
                const result = await fetchLookupData(lookupDataRequest, FileSession);
                if (result.data.Data) {
                    const transformedData = result.data.Data.map((item: any) => ({
                        id: item.id,
                        name: item.name
                    }));
                    setLookupData(prev => ({
                        ...prev,
                        [property.o_propname]: transformedData
                    }));
                }
            } catch (error) {
                console.info('Error fetching lookup data:', error);
            }
        }
    }, [lookupData, FileSession]);

    const handleLookupFocus = async (property: any) => {
        await fetchLookupDataForProperty(property);
        setSelectedDocumentClass((prevSelectedClass) => prevSelectedClass); // Retain document class selection
    };

    const mapIdsToTextValues = (properties: any[], lookupData: Record<string, Array<{ id: string; name: string }>>) => {
        const mappedProperties: Record<string, any> = {};
        properties.forEach((property: any) => {
            if (property.m_datatype === 'Lookup' && property.text) {
                mappedProperties[property.o_propname] = property.text;
            } else {
                mappedProperties[property.o_propname] = property.value;
            }
        });
        return mappedProperties;
    };


    useEffect(() => {
        async function fetchClassProperties() {
            try {

                const result = await fetchClassPropertiesService(FileSession);
                if (result?.data) {
                    const fileClasses = result.data.Data.classes;
                    const itemClassResponse: FolderItemClassResponse = {
                        classes: fileClasses.map((classItem: any) => ({
                            model: classItem?.model || '',
                            name: classItem?.name || '',
                            obj_int_id: classItem?.obj_int_id || null,
                            o_classname: classItem?.o_classname || '',
                            m_objtype_alias: classItem?.m_objtype_alias || '',
                            m_class_alias: classItem?.m_class_alias || '',
                            creation_date: classItem?.creation_date || '',
                            objects: classItem?.objects?.map((objectItem: any) => ({
                                o_objname: objectItem?.o_objname || '',
                                o_ID: objectItem?.o_ID || null,
                                m_ID: objectItem?.m_ID || null,
                                o_docid: objectItem?.o_docid || null,
                                o_attachments: objectItem?.o_attachments || null,
                                created_by: objectItem?.created_by || null,
                                modified_by: objectItem?.modified_by || null,
                                created_by_mfile_user: objectItem?.created_by_mfile_user || null,
                                m_class_alias: objectItem?.m_class_alias || '',
                                version: {
                                    created: objectItem?.version?.created || '',
                                    lastmodified: objectItem?.version?.lastmodified || '',
                                    properties:
                                        objectItem?.version?.properties?.map((property: any) => ({
                                            o_propname: property?.o_propname || '',
                                            m_datatype: property?.m_datatype || '',
                                            m_alias: property?.m_alias || '',
                                            value: property?.value || null,
                                            text: property?.text || '',
                                            referece_class_alias: property?.referece_class_alias || '',
                                            relational_table: property?.relational_table || null,
                                            is_obligatory: property?.is_obligatory || false,
                                            is_editable: property?.is_editable || false,
                                            query: property?.query || '',
                                            value_list_data: property?.value_list_data || [],
                                        })) || [],
                                },
                                find_rule: objectItem?.find_rule || [],
                            })) || [],
                        })) || [],
                    };
                    setFolderItemClassResponse(itemClassResponse);
                } else {
                    showToast("An error occurred. Please refresh and try again", "Attached Document", "e-toast-danger");
                }
            } catch (e) {
                console.info("Error fetching class properties:", e);
            }
        }

        fetchClassProperties();
    }, [fetchClasses, FileSession]);

    //useEffect(() => {
    //    async function fetchSelectedFileContent() {
    //        try {
    //            if (FileDetailsObject && FileDetailsObject.id) {
    //                const result = await filePropertyService(FileDetailsObject.id, FileSession);
    //                if (result.data) {
    //                    if (result.data.Success) {
    //                        const fileClasses = result.data.Data as unknown as FileDetailsObject;
    //                        const jsonPropertiesObj = JSON.parse(fileClasses.jsonProperties);
    //                        if (jsonPropertiesObj) {
    //                            const mainClassProperties = {
    //                                model: jsonPropertiesObj.model,
    //                                name: jsonPropertiesObj.name,
    //                                obj_int_id: jsonPropertiesObj.obj_int_id,
    //                                o_classname: jsonPropertiesObj.o_classname,
    //                                m_objtype_alias: jsonPropertiesObj.m_objtype_alias,
    //                                m_class_alias: jsonPropertiesObj.m_class_alias,
    //                                creation_date: jsonPropertiesObj.creation_date
    //                            };

    //                            if (jsonPropertiesObj.objects) {
    //                                const properties: Record<string, any> = {};
    //                                for (const objectInfo of jsonPropertiesObj.objects) {
    //                                    const mappedProperties = mapIdsToTextValues(objectInfo.version.properties, lookupData);
    //                                    Object.assign(properties, mappedProperties);
    //                                }
    //                                setSelectedDocumentClass(mainClassProperties.name);
    //                                setTimeout(() => {
    //                                    setDynamicProperties(properties);
    //                                }, 500);
    //                            } else {
    //                                setSelectedDocumentClass(null);
    //                                setDynamicProperties({});
    //                            }
    //                        } else {
    //                            setSelectedDocumentClass(null);
    //                            setDynamicProperties({});
    //                        }
    //                    } else {
    //                        setSelectedDocumentClass(null);
    //                        setDynamicProperties({});
    //                    }
    //                }
    //            }
    //        } catch (e) {
    //            console.info(e);
    //        }
    //    }

    //    fetchSelectedFileContent();

    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [registerChange]);

    useEffect(() => {
        async function fetchSelectedFileContent() {
            try {
                if (FileDetailsObject && FileDetailsObject.id) {
                    let jsonProperties: any = FileDetailsObject.jsonProperties;
                    if (jsonProperties) {
                        const jsonPropertiesObj = JSON.parse(jsonProperties);
                        if (jsonPropertiesObj) {
                            const mainClassProperties = {
                                model: jsonPropertiesObj.model,
                                name: jsonPropertiesObj.name,
                                obj_int_id: jsonPropertiesObj.obj_int_id,
                                o_classname: jsonPropertiesObj.o_classname,
                                m_objtype_alias: jsonPropertiesObj.m_objtype_alias,
                                m_class_alias: jsonPropertiesObj.m_class_alias,
                                creation_date: jsonPropertiesObj.creation_date
                            };

                            if (jsonPropertiesObj.objects) {
                                const properties: Record<string, any> = {};
                                for (const objectInfo of jsonPropertiesObj.objects) {
                                    const mappedProperties = mapIdsToTextValues(objectInfo.version.properties, lookupData);
                                    Object.assign(properties, mappedProperties);
                                }
                                setSelectedDocumentClass(mainClassProperties.name);
                                setTimeout(() => {
                                    setDynamicProperties(properties);
                                }, 500);
                            } else {
                                setSelectedDocumentClass(null);
                                setDynamicProperties({});
                            }
                        } else {
                            setSelectedDocumentClass(null);
                            setDynamicProperties({});
                        }
                    } else {
                        setSelectedDocumentClass(null);
                        setDynamicProperties({});
                    }
                }
            } catch (e) {
                console.info(e);
            }
        }

        fetchSelectedFileContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FileDetailsObject]);

    useEffect(() => {
        selectedDocumentClassRef.current = selectedDocumentClass;

        if (selectedDocumentClass && selectedDocumentClass.length > 0) {
            handleSelectionSaveFunction();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocumentClass]);

    const handleDocumentClassChange = (selectedClass: string) => {
        setSelectedDocumentClass(selectedClass);
        setDynamicProperties({});
    };

    const handleInputChange = (propertyName: string, value: string) => {
        if (value) {
            setDynamicProperties((prevProperties) => ({
                ...prevProperties,
                [propertyName]: value,
            }));
            doRegisterChange(prev => prev + 1);
        }
    };

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFileDetails = { ...FileDetailsObject, id: e.target.value };
        setSelectedId(e.target.value);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFileDetails = { ...FileDetailsObject, name: e.target.value };
    };

    const handleDocumentClassFetch = () => {
        doFetchClasses(prev => prev + 1);
    };

    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 5000,
            cssClass: cssClass
        });
    };

    const handleSelectionSaveFunction = async () => {
        try {
          
            if (selectedDocumentClass) {
                return;
            }

            // Parse and validate FileDetailsObject.jsonProperties
            const jsonProperties = FileDetailsObject?.jsonProperties
                ? JSON.parse(FileDetailsObject.jsonProperties)
                : null;

            if (jsonProperties && jsonProperties.name) {
                return;
            }

            // Proceed if no class was previously selected or assigned
            const existingClass = folderItemClassResponse?.classes.find(
                (folderItemClass) => folderItemClass.name === selectedDocumentClass
            );

            if (!existingClass) {
                return;
            }

           // debugger;
            //console.log("Saving with ID:", FileDetailsObject.id);
            //showToast("Saving property changes to : " + FileDetailsObject.id, "File Properties", "e-toast-info");
            // Prepare dynamic properties list from the existing class
            const dynamicPropertiesList: FileClassProperty[] = existingClass.objects[0].version.properties.map((property) => {
                const value = dynamicProperties[property.o_propname];
                let text = value;

                if (property.m_datatype === 'Lookup' || property.m_datatype === 'ValueList') {
                    const selectedItem = lookupData[property.o_propname]?.find(item => item.id === value);
                    if (selectedItem) {
                        text = selectedItem.name;
                    }
                }

                return {
                    o_propname: property.o_propname,
                    m_datatype: property.m_datatype,
                    m_alias: property.m_alias,
                    value: value,
                    text: text,
                    referece_class_alias: property.referece_class_alias,
                    relational_table: property.relational_table,
                    is_obligatory: property.is_obligatory,
                    is_editable: property.is_editable,
                    query: property.query,
                    value_list_data: property.value_list_data,
                };
            });


            // Create a new request based on the existing class properties
            const fileClassRequest: FileClassRequest = {
                classes: [
                    {
                        model: existingClass.model,
                        name: existingClass.name,
                        obj_int_id: existingClass.obj_int_id,
                        o_classname: existingClass.o_classname,
                        m_objtype_alias: existingClass.m_objtype_alias,
                        m_class_alias: existingClass.m_class_alias,
                        creation_date: existingClass.creation_date,
                        objects: [
                            {
                                o_objname: FileDetailsObject?.name,
                                o_ID: FileDetailsObject?.id,
                                m_ID: null,
                                o_docid: null,
                                o_attachments: null,
                                created_by: FileDetailsObject?.jsonProperties?.created_by,
                                modified_by: FileDetailsObject?.jsonProperties?.modified_by,
                                created_by_mfile_user: FileDetailsObject?.jsonProperties?.created_by_mfile_user,
                                m_class_alias: existingClass.m_class_alias,
                                version: {
                                    created: new Date().toISOString(),
                                    lastmodified: new Date().toISOString(),
                                    properties: dynamicPropertiesList,
                                },
                                find_rule: existingClass.objects[0].find_rule
                            }
                        ]
                    }
                ]
            };

            // Compare the saved class (FileDetailsObject.jsonProperties) with the existing one
            const isClassDifferent = JSON.stringify(FileDetailsObject.jsonProperties) !== JSON.stringify(existingClass);

            if (!isClassDifferent) {
                return;
            }

            // If the classes are different, replace the incoming class with the existing class properties
            const result = await saveClassPropertiesService(fileClassRequest, FileDetailsObject.id, FileSession);

        } catch (error) {
            console.info(error);
            showToast("An error occurred", "File Properties", "e-toast-danger");
        }
    };

    const handleSaveFunction = async () => {
        try {
            // Fetch the current ID value directly from the input field
            const currentId = document.querySelector<HTMLInputElement>('.properties-container .e-input.dynamic-input')?.value;

            // Convert the currentId to a number
            const numericId = currentId ? parseInt(currentId, 10) : null;

            if (!numericId) {
                showToast("Invalid ID. Please ensure the input is a valid number.", "File Properties", "e-toast-danger");
                return;
            }

            console.log("Saving with ID:", numericId);

            const existingClass = folderItemClassResponse?.classes.find(
                (folderItemClass) => folderItemClass.name === selectedDocumentClass
            );

            if (!existingClass) {
                showToast("Selected class not found", "File Properties", "e-toast-warning");
                return;
            }

            // Prepare dynamic properties list from the existing class
            const dynamicPropertiesList = existingClass.objects[0].version.properties.map((property) => {
                const value = dynamicProperties[property.o_propname];
                let text = value;

                if (property.m_datatype === 'Lookup' || property.m_datatype === 'ValueList') {
                    const selectedItem = lookupData[property.o_propname]?.find((item) => item.id === value);
                    if (selectedItem) {
                        text = selectedItem.name;
                    }
                }

                return {
                    o_propname: property.o_propname,
                    m_datatype: property.m_datatype,
                    m_alias: property.m_alias,
                    value: value,
                    text: text,
                    referece_class_alias: property.referece_class_alias,
                    relational_table: property.relational_table,
                    is_obligatory: property.is_obligatory,
                    is_editable: property.is_editable,
                    query: property.query,
                    value_list_data: property.value_list_data,
                };
            });

            // Create a new request based on the input field's numeric ID
            const fileClassRequest: FileClassRequest = {
                classes: [
                    {
                        model: existingClass.model,
                        name: existingClass.name,
                        obj_int_id: existingClass.obj_int_id,
                        o_classname: existingClass.o_classname,
                        m_objtype_alias: existingClass.m_objtype_alias,
                        m_class_alias: existingClass.m_class_alias,
                        creation_date: existingClass.creation_date,
                        objects: [
                            {
                                o_objname: FileDetailsObject.name,
                                o_ID: numericId, // Use the numeric value
                                m_ID: null,
                                o_docid: null,
                                o_attachments: null,
                                created_by: FileDetailsObject?.jsonProperties?.created_by,
                                modified_by: FileDetailsObject?.jsonProperties?.modified_by,
                                created_by_mfile_user: FileDetailsObject?.jsonProperties?.created_by_mfile_user,
                                m_class_alias: existingClass.m_class_alias,
                                version: {
                                    created: new Date().toISOString(),
                                    lastmodified: new Date().toISOString(),
                                    properties: dynamicPropertiesList,
                                },
                                find_rule: existingClass.objects[0].find_rule,
                            },
                        ],
                    },
                ],
            };
            showToast("Saving property changes to : " + numericId.toString(), "File Properties", "e-toast-info");

            const result = await saveClassPropertiesService(fileClassRequest, numericId.toString(), FileSession);

            if (result.data && result.data.Success) {
                showToast("Save successful : " + numericId.toString(), "File Properties", "e-toast-success");
                try {
                    onRefreshFunction();
                } catch (e) {
                    console.info(e);
                }
            } else {
                showToast("An error occurred", "File Properties", "e-toast-warning");
            }
        } catch (error) {
            console.info(error);
            showToast("An error occurred", "File Properties", "e-toast-danger");
        }
    };


    const getLookupTextForId = (propertyName: string, id: string) => {
        const lookupItem = lookupData[propertyName]?.find(item => item.id === id);
        return lookupItem ? lookupItem.name : id;
    };

    const handleCancelFunction = () => {
        // Handle cancel logic if needed
    };

    const handleResetFunction = () => {
        // Handle reset logic if needed
    };

    const renderControls = () => {
        if (selectedDocumentClass && folderItemClassResponse && folderItemClassResponse.classes) {
            const properties = folderItemClassResponse.classes
                .find(folderItemClass => folderItemClass.name === selectedDocumentClass)
                ?.objects[0]?.version?.properties;

            if (!properties) {
                return null;
            }

            return properties.map((property) => {
                let inputElement: JSX.Element | null = null;


                if (property.m_datatype === 'Binary') {
                    return null;
                }
                switch (property.m_datatype) {
                    case 'Date':
                        inputElement = (
                            <DatePickerComponent
                                className="e-input dynamic-input"
                                style={{ backgroundColor: '#2e2e2e', color: 'white', borderColor: '#2e2e2e' }}
                                value={new Date(dynamicProperties[property.o_propname] || new Date())}
                                onChange={(date: any) => handleInputChange(property.o_propname, date.toString())}
                            />
                        );
                        break;
                    case 'MultiSelect':
                        inputElement = (
                            <MultiSelectComponent
                                className="e-input dynamic-input"
                                dataSource={property.value_list_data.map(item => ({ Name: item.name, Id: item.id }))}
                                mode="Box"
                                fields={{ text: 'Name', value: 'Id' }}
                                value={dynamicProperties[property.o_propname]?.split(',')}
                                change={(e) => handleInputChange(property.o_propname, e.value.join(','))}
                            />
                        );
                        break;
                    case 'Lookup':
                        const selectedItem = lookupData[property.o_propname]?.find(item => item.id === dynamicProperties[property.o_propname]);
                        const selectedValue = selectedItem ? selectedItem.name : dynamicProperties[property.o_propname];
                        inputElement = (
                            <ComboBoxComponent
                                dataSource={lookupData[property.o_propname] || []}
                                onFocus={() => handleLookupFocus(property)}
                                fields={{ text: 'name', value: 'id' }}
                                allowFiltering={true}
                                autofill={true}
                                style={{ backgroundColor: '#2e2e2e', color: 'white', borderColor: '#2e2e2e' }}
                                placeholder='Select an option'
                                value={dynamicProperties[property.o_propname] || ''}
                                change={(e) => handleInputChange(property.o_propname, e.itemData?.id)}
                            />
                        );
                        break;
                    case 'ValueList':
                        const valueListData = property.value_list_data.map(item => {
                            const key = Object.keys(item)[0];
                            return { id: key, name: item[key] };
                        });

                        const selectedValueListItem = valueListData.find(item => item.id === dynamicProperties[property.o_propname]);
                        const selectedValueListName = selectedValueListItem ? selectedValueListItem.name : dynamicProperties[property.o_propname];

                        inputElement = (
                            <ComboBoxComponent
                                dataSource={valueListData}
                                fields={{ text: 'name', value: 'id' }}
                                allowFiltering={true}
                                autofill={true}
                                placeholder="Select an option"
                                style={{ backgroundColor: '#2e2e2e', color: 'white', borderColor: '#2e2e2e' }}
                                value={selectedValueListName || ''}
                                change={(e) => handleInputChange(property.o_propname, e.itemData?.id)}
                            />
                        );
                        break;
                    default:
                        inputElement = (
                            <input
                                type="text"
                                className="e-input dynamic-input"
                                value={dynamicProperties[property.o_propname] || ''}
                                onChange={(e) => handleInputChange(property.o_propname, e.target.value)}
                            />
                        );
                        break;
                }

                return (
                    <div key={property.o_propname} className="properties-row">
                        <div className="properties-label">{property.o_propname}</div>
                        <div className="properties-value">{inputElement}</div>
                    </div>
                );
            });
        }
        return null;
    };

    return (
        <div className="properties-container">
            <div className="properties-sub-container">
                <div className="properties-row">
                    <div className="properties-label">Id</div>
                    <div className="properties-value">
                        <input
                            type="text"
                            className="e-input dynamic-input"
                            value={FileDetailsObject.id}
                            onChange={handleIdChange}
                        />
                    </div>
                </div>

                <div className="properties-row">
                    <div className="properties-label">File Name</div>
                    <div className="properties-value">
                        <input
                            type="text"
                            className="e-input dynamic-input"
                            value={FileDetailsObject.name}
                            onChange={handleNameChange}
                        />
                    </div>
                </div>

                <div className="properties-row">
                    <div className="properties-label">Document Class</div>
                    <div className="properties-value">
                        {folderItemClassResponse && folderItemClassResponse.classes && folderItemClassResponse.classes.length > 0 ? (
                            <ComboBoxComponent
                                className="dynamic-input"
                                dataSource={folderItemClassResponse.classes.map((folderItemClass) => folderItemClass.name)}
                                onClick={() => {
                                    try {
                                        handleDocumentClassFetch();
                                    } catch (error) {
                                        console.info('Error fetching document classes:', error);
                                        showToast("Failed to fetch document classes. Please try again.", "Document Class", "e-toast-danger");
                                    }
                                }}
                                placeholder="Select Document Class"
                                autofill={true}
                                value={selectedDocumentClass || ''}
                                change={(e) => {
                                    try {
                                        handleDocumentClassChange(e.value);
                                    } catch (error) {
                                        console.info('Error selecting document class:', error);
                                        showToast("An error occurred while selecting the document class.", "Document Class", "e-toast-danger");
                                    }
                                }}
                            />
                        ) : (
                            <div>No document classes available</div> // Graceful fallback if no classes are available
                        )}
                    </div>
                </div>


                {renderControls()}
            </div>
            <div className="properties-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className="e-toolbar-item">
                    <ButtonComponent
                        iconCss="e-icons e-save-changes"
                        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib properties-button"
                        onClick={handleSaveFunction}
                        title="Save"
                        iconPosition="Left"
                    >
                        Save
                    </ButtonComponent>
                </div>

                {/*<div className="e-toolbar-item">*/}
                {/*    <ButtonComponent*/}
                {/*        iconCss="e-icons e-refresh"*/}
                {/*        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib properties-button"*/}
                {/*        iconPosition="Left"*/}
                {/*        title="Reset"*/}
                {/*        onClick={handleResetFunction}*/}
                {/*    >*/}
                {/*        Reset*/}
                {/*    </ButtonComponent>*/}
                {/*</div>*/}

                {/*<div className="e-toolbar-item">*/}
                {/*    <ButtonComponent*/}
                {/*        iconCss="e-icons e-cancel-action"*/}
                {/*        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib properties-button"*/}
                {/*        iconPosition="Left"*/}
                {/*        title="Cancel"*/}
                {/*        onClick={handleCancelFunction}*/}
                {/*    >*/}
                {/*        Cancel*/}
                {/*    </ButtonComponent>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
