import { Split } from '@geoffcox/react-splitter';
import { GlobalAnimationMode, setGlobalAnimation } from "@syncfusion/ej2-base";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-notifications';
import {
    BeforeDownloadEventArgs,
    BeforePopupOpenCloseEventArgs,
    DetailsView,
    FailureEventArgs,
    FileLoadEventArgs,
    FileManagerComponent,
    FileOpenEventArgs,
    FileSelectEventArgs,
    FileSelectionEventArgs,
    Inject,
    MenuClickEventArgs,
    MenuOpenEventArgs,
    NavigationPane,
    PopupOpenCloseEventArgs,
    SuccessEventArgs,
    Toolbar,
    ToolbarClickEventArgs
} from '@syncfusion/ej2-react-filemanager';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { ItemModel, TabComponent } from '@syncfusion/ej2-react-navigations';
import { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    fetchClassPropertiesService,
    fileAddAttachmentService,
    fileAttachedConnectionsService,
    fileCreateFolderService,
    fileGetConnectionsService,
    fileOpenService,
    fileRemoveSelectedAttachedService,
    fileRemoveService,
    fileRenameService,
    refreshFileService
} from '../../apis/fileService';
import { PDF_SERVICE_URL, SERVER_URL } from '../../environment/env_dev';
import { AttachedItem } from '../../models/AttachedItem';
import { FileAttachmentRequest } from '../../models/FileAttachmentRequest';
import { FileDetailsObject } from '../../models/FileDetails';
import { FileItem } from '../../models/FileItem';
import { FolderItemClassResponse } from '../../models/FileItemClasses';
import { FileProviderConnection } from '../../models/FileProviderConnection';
import { FileSession } from '../../models/FileSession';
import { RemoveAttachedConnectionsRequest } from '../../models/RemoveAttachedConnectionsRequest';
import { ConnectedSourceItemsRequest } from '../../models/connectedSourceItemsRequest';
import { selectFile } from '../../store/actions';
import { getFile } from '../../store/selectors/fileSelector';
import { getFormattedDate } from '../../utilities/Formatters';
import { ActionType, ProcessorType, getFileExtension, getImageSrc, isDocumentProcessorSupported, isDocumentSupportedForAnyProcessor } from '../../utilities/SupportedFileFormats';
import "../FileManager/FileManagerCore.scss";
import FileManagerDocumentPreviewer from './FileManagerDocumentPreviewer';
import FileManagerMergePdfFiles from './FileManagerMergePdfFiles';
import FileManagerPDFViewer from './FileManagerPDFViewer';
import FileManagerPreviewerDialog from './FileManagerPreviewerDialog';
import FileManagerRichTextEditorPreview from './FileManagerRichTextEditorPreview';
import FileManagerSpreadsheetPreviewer from './FileManagerSpreadsheetPreviewer';
import FileManagerStyledDialog from './FileManagerStyledDialog';
import FileProperties from './FileProperties';
import { PdfJsPreviewer } from './PdfJsPreviewer';

interface Props {
    fileSession: FileSession;
}
export default function FileManagerCore({ fileSession }: Props) {

    //Context menu custom actions
    const [fileActions, setFileActions] = useState(['Open', 'Edit', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
    const [folderActions, setFolderActions] = useState(['Open', '|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
    const layoutActions = ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', 'Upload', '|', 'Details', '|', 'SelectAll'];
    const [toolbarItems, setToolbarItems] = useState([
        /*{ text: 'Roots', name: 'RootConfiguration', prefixIcon: 'e-folder', tooltipText: 'Roots' },*/
        { text: 'Create folder', name: 'NewFolder', prefixIcon: 'e-plus', tooltipText: 'Create folder' },
        { name: 'Upload' },
        { name: 'SortBy' },
        { name: 'Refresh' },
        { name: 'Cut' },
        { name: 'Copy' },
        { name: 'Paste' },
        { name: 'Delete' },
        { name: 'Download' },
        { name: 'Rename' },
        { name: 'Selection' },
        { name: 'View' },
        { name: 'Details' }]);


    //File Manager References
    const fileManagerRef = useRef<FileManagerComponent>(null);
    const pdfViewerRef = createRef<typeof FileManagerPDFViewer>();

    //Dialog visibilities
    const [filePreviewDialogVisibility, setFilePreviewDialogVisibility] = useState<boolean>(false);
    const [fileOpenDialogVisibility, setFileOpenDialogVisibility] = useState<boolean>(false);
    const [fileMergeDialogVisibility, setFileMergeDialogVisibility] = useState<boolean>(false);
    const [fileRenameDialogVisibility, setFileRenameDialogVisibility] = useState<boolean>(false);
    const [fileCreateDialogVisibility, setFileCreateDialogVisibility] = useState<boolean>(false);
    const [fileAttachmentsDialogVisibility, setFileAttachmentsDialogVisibility] = useState<boolean>(false);
    const [fileDeleteDialogVisibility, setFileDeleteDialogVisibility] = useState<boolean>(false);
    const [fileDetailsDialogVisibility, setFileDetailsDialogVisibility] = useState<boolean>(false);
    const [fileDuplicateItemsDialogVisibility, setFileDuplicateItemsDialogVisibility] = useState<boolean>(false);

    //Variables used on a selected item in the File Manager
    const [currentFileName, setCurrentFileName] = useState("");
    const [currentFileId, setCurrentFileId] = useState("");
    //const [currentFilePath, setCurrentFilePath] = useState("");
    const [currentFileType, setCurrentFileType] = useState("");
    const [currentFileProvider] = useState("root");
    const [currentSelectedFiles, setCurrentSelectedFiles] = useState<FileItem[]>([]);
    const selectedFileReduced = useSelector(getFile);
    const dispatch = useDispatch();

    //Variables used on a selected item to be manipulated
    const [temporaryName, setTemporaryName] = useState("");
    const [newFolderName, setNewFolderName] = useState("");

    //Other variables
    const [showPreview] = useState<boolean>(true);
    const [showImageFileDialog] = useState<boolean>(false);
    const [showPdfFileDialog, setShowPdfFileDialog] = useState<boolean>(false);
    const [showTextFileDialog] = useState<boolean>(false);
    const [repaintRequested, setRepaintRequested] = useState(0);
    const [currentCache, setCurrentCache] = useState<FileItem[]>([]);
    //const [currentPropertiesCache, setCurrentPropertiesCache] = useState<FileItem[]>([]);
    const uploadFileSizeLimit = 2 * 1024 * 1024 * 1024;
    const [refresh, doRefresh] = useState(0);
    const [refreshPreviewer, doRefreshPreviewer] = useState(0);
    const [resize, doResize] = useState(0);
    const [savePdfChanges, setSavePdfChanges] = useState(0);
    const [calledArgs, setCalledArgs] = useState<{ path: string; name: string }[]>([]);
    const [hasServerConnectionError, setHasServerConnectionError] = useState<boolean>(false);

    //Hub Connection
    const [minSecondarySize, setMinSecondarySize] = useState('35px');
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    //Attached items
    const [attachedItems, setAttachedItems] = useState<AttachedItem[]>([]);

    const attachedItemFields: object = { text: 'name', value: 'id' };
    const [folderItemClassResponse, setFolderItemClassResponse] = useState<FolderItemClassResponse>();

    //Param Queries
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const displayVersion = queryParams.get('display') ?? "";
    const attachedModel = queryParams.get('UniqueSourceId') ?? "";

    // create a new hub connection
    useEffect(() => {
        setGlobalAnimation(GlobalAnimationMode.Disable);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (displayVersion === "select") {
            setToolbarItems([
                /* { text: 'Roots', name: 'RootConfiguration', prefixIcon: 'e-folder', tooltipText: 'Roots' },*/
                { text: 'Create folder', name: 'NewFolder', prefixIcon: 'e-plus', tooltipText: 'Create folder' },
                { name: 'Upload' },
                { name: 'SortBy' },
                { name: 'Refresh' },
                { name: 'Cut' },
                { name: 'Copy' },
                { name: 'Paste' },
                { name: 'Delete' },
                { name: 'Download' },
                { name: 'Rename' },
                { text: 'Attachments', name: 'Attachments', prefixIcon: 'e-add-attachment', tooltipText: 'Attachments' },
                { name: 'Selection' },
                { name: 'View' },
                { name: 'Details' }]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchSelectedFileContent() {
            try {
                const result = await fetchClassPropertiesService(fileSession);
                if (result.data) {
                    if (result.data.Success) {
                        const fileClasses = result.data.Data;
                        let itemClassResponse: FolderItemClassResponse = {
                            classes: fileClasses.classes.map((classItem: any) => ({
                                className: classItem.class_name,
                                classId: classItem.class_id,
                                classProperties: classItem.class_properties.map((property: any) => ({
                                    propertyName: property.property_name,
                                    type: property.type,
                                    relationalTable: property.relational_table,
                                    isObligatory: property.is_obligatory,
                                    isEditable: property.is_editable,
                                    query: property.query,
                                    valueListData: property.value_list_data,
                                })),
                            })),
                        };

                        setFolderItemClassResponse(itemClassResponse);
                    } else {
                        //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                    }
                }
            } catch (e) {
            }
        }

        fetchSelectedFileContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //fileAttachedConnectionsService
    useEffect(() => {
        if (fileAttachmentsDialogVisibility) {
            async function fetchSelectedFileContent() {
                try {

                    let fileAttachmentRequests: ConnectedSourceItemsRequest = new ConnectedSourceItemsRequest(attachedModel);
                    setAttachedItems([]);
                    const result = await fileAttachedConnectionsService(fileAttachmentRequests, fileSession);
                    if (result.data) {
                        if (result.data.Success) {
                            const fileClasses = result.data.Data as any[];
                            fileClasses.forEach(
                                (a) => {
                                    const newFileItem = new AttachedItem(a.Target, a.Name, a.Name);

                                    setAttachedItems(prevFileItems => [
                                        ...prevFileItems,
                                        newFileItem,
                                    ]);
                                }
                            );


                        } else {
                            showToast("An error occurred. Please refresh and try again", "Attached Items", "e-toast-danger");
                        }
                    }
                } catch (e) {
                    // Handle errors
                }
            }

            fetchSelectedFileContent();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileAttachmentsDialogVisibility]);

    //Functions to be executed when the selected file changes
    useEffect(() => {
        if (selectedFileReduced && selectedFileReduced?.isFile) {
            setFileOpenDialogVisibility(false);
            //var path = selectedFileReduced?.id;
            var fileName = selectedFileReduced?.name;
            var fileId = selectedFileReduced?.id;
            var fileType = selectedFileReduced?.type;
            //var currentItem = fileManagerRef?.current?.itemData[0] as unknown as FileDetailsObject;

            setTemporaryName(fileName);
            setCurrentFileName(fileName);
            setCurrentFileType(fileType);
            //setCurrentFilePath(path);
            setCurrentFileId(fileId);
            //search
            //Update Menu Items  when user right clicks a file item
            if ((fileType === 'pdf' || fileType === '.pdf') && currentSelectedFiles.length > 1) {
                if (currentSelectedFiles.length > 1)
                    if (displayVersion === "select")
                        setFileActions(['Preview', 'Merge', 'Attach', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
                    else setFileActions(['Preview', 'Merge', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
                else
                    setFileActions(['Preview', 'Edit', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
            }
            else {
                if (currentSelectedFiles && currentSelectedFiles.length > 1) {
                    if (displayVersion === "select")
                        setFileActions(['Cut', 'Copy', 'Attach', '|', 'Delete', 'Download', '|']);
                    else
                        setFileActions(['Cut', 'Copy', '|', 'Delete', 'Download', '|']);
                }
                else {
                    if (displayVersion === "select")
                        setFileActions(['Preview', '|', 'Cut', 'Copy', 'Attach', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
                    else
                        setFileActions(['Preview', 'Edit', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFileReduced]);

    useEffect(() => {
        async function repaint() {
            try {
                setTimeout(() => {
                    if (selectedFileReduced?.id === "0" || !selectedFileReduced) {
                        // Select all rows in the details section
                        const rowNodes = fileManagerRef.current?.element?.querySelectorAll(".e-row");

                        rowNodes?.forEach((rowNode) => {
                            try {
                                // Select the text node to get the filename
                                const textNode = rowNode.querySelectorAll(".e-fe-text");
                                const fileName = textNode[0]?.textContent?.trim();
                                const fileExt = getFileExtension(fileName);

                                // Check if the filename does not have an extension and is not empty
                                if (fileExt === fileName) {
                                    // Find the specific td containing the icon element within the row
                                    const iconTd = rowNode.querySelector(".e-rowcell.e-templatecell.e-fe-grid-icon");
                                    if (iconTd) {
                                        const folderIcon = iconTd.querySelector(".e-fe-icon.e-fe-folder");

                                        // Check if folderIcon is found and log for debugging
                                        //if (folderIcon) {
                                        //    folderIcon.setAttribute(
                                        //        "style",
                                        //        "filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6) !important;"
                                        //    );
                                        //} else {
                                        //    console.warn(`Folder icon not found in td for row with filename: ${fileName}`);
                                        //}
                                    } else {
                                        console.warn(`Icon td not found for row with filename: ${fileName}`);
                                    }
                                }
                            } catch (e) {
                                console.info("Error styling icon:", e);
                            }
                        });

                        const largeIconNodes = fileManagerRef.current?.element?.querySelectorAll(
                            ".e-large-icon"
                        );
                        largeIconNodes?.forEach((iconNode) => {
                            try {
                                const textContentNode = iconNode.querySelector(".e-text-content");
                                const fileName = textContentNode?.querySelector(".e-list-text")?.textContent?.trim();
                                const fileExt = getFileExtension(fileName);
                                debugger;
                                if (fileExt === fileName) {
                                    const folderIcon = textContentNode?.querySelector(".e-list-icon.e-fe-folder");
                                    //if (folderIcon) {
                                    //    console.log(`Styling large icon for: ${fileName}`);
                                    //    folderIcon.setAttribute(
                                    //        "style",
                                    //        "filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6) !important;"
                                    //    );
                                    //} else {
                                    //    console.warn(`Folder icon not found in large icons view for: ${fileName}`);
                                    //}
                                }
                            } catch (e) {
                                console.info("Error styling large icon:", e);
                            }
                        });
                    }
                }, 10);
            } catch (error) {
                console.log('There has been a problem with your fetch operation: ', error);
            }
        }

        repaint();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repaintRequested]);

    //fetches the file bytes as base64 string from the api - called on each file selection
    const loadFileBytes = async (id: string, name: string, provider: string) => {
        const fileItem = currentCache.find(fileItem => fileItem.id === id);
        if (!fileItem) {
            const result = await fileOpenService(id, name, provider ?? "", fileSession);
            var data = result?.data?.Data;
            if (data) {
                const newFileItem = new FileItem(
                    data.Id.toString(),
                    data.FileName,
                    data.FileBase64String,
                    data.RelativePath,
                    data.currentCache?.length ?? 0,
                    data.ChildJson,
                    data.ParentJson,
                    data.ProviderId
                );

                setCurrentCache(prevFileItems => {
                    const newCache = [newFileItem, ...prevFileItems];
                    // If the cache size exceeds 5, remove the oldest items
                    return newCache.length > 5 ? newCache.slice(0, 5) : newCache;
                });
            }
        }
    };


    const refreshFileManager = () => {
        var viewer = (document.getElementById("file") as any).ej2_instances[0];
        if (viewer) {
            console.log("refreshing the file manager");
            viewer?.refreshFiles(() => {
                let filterPath = selectedFileReduced?.filterPath;
                if (filterPath) {
                    selectParentFolder(filterPath);
                }
            });
            fileManagerRef.current?.refreshFiles();
        }
    }

    //Called whenever the FileManager completes a request
    const onSuccess = (e: SuccessEventArgs) => {

        try {
            if (e?.action === "move" || e?.action === "copy") {
                var actionResult = e?.result as any;
                if (actionResult?.name === "drag-end") {
                    var firstSelectedItem = actionResult.files.at(0) as unknown as FileDetailsObject;

                    if (firstSelectedItem && fileManagerRef && fileManagerRef.current) {
                        fileManagerRef.current.clearSelection();
                        fileManagerRef.current.refreshFiles();
                    }
                }
                else {
                    refreshFileManager();
                }
            }

            if (hasServerConnectionError && e.action === "read") {
                setHasServerConnectionError(false);
            }
        } catch (e) {

        }

        //try {
        //    loadFileBytes(selectedFileReduced?.path ?? "", selectedFileReduced?.name ?? "", selectedFileReduced?.fileProviderId ?? "");
        //} catch (e) {
        //    console.log("Application Error: onSuccess: ", e);
        //}

    }


    ////File Manager Core Function
    //const onFileLoad = (e: FileLoadEventArgs) => {

    //    var nodes = fileManagerRef.current?.element?.getElementsByClassName("e-list-item");
    //    for (var i = 0; i < nodes!.length; i++) {
    //        var textContentNode = nodes![i].getElementsByClassName("e-text-content");
    //        var folderIcon = textContentNode?.item(0)?.querySelectorAll(".e-filemanager,.e-fe-folder").item(0);
    //        var listTextNode = textContentNode?.item(0)?.getElementsByClassName("e-list-text").item(0)?.textContent;
    //        var args = e.fileDetails as FileDetailsObject;
    //        if (args?.isRoot && args?.name === listTextNode) {
    //            console.log("The FileLoadEventArgs=>" + folderIcon?.attributes);
    //            folderIcon?.setAttribute('style', 'filter: brightness(0.5) sepia(1) hue-rotate(145deg) saturate(6);');
    //            console.log(folderIcon?.attributes);
    //        }
    //    }
    //    //var args = e.fileDetails as FileDetailsObject;
    //}

    const onFileLoad = (e: FileLoadEventArgs) => {
        // First loop to handle tree view nodes
        const nodes = fileManagerRef.current?.element?.getElementsByClassName("e-list-item");
        const args = e.fileDetails as FileDetailsObject;
        for (let i = 0; i < nodes!.length; i++) {
            //const textContentNode = nodes![i].getElementsByClassName("e-text-content");
            //const folderIcon = textContentNode?.item(0)?.querySelectorAll(".e-filemanager,.e-fe-folder").item(0);
            //const listTextNode = textContentNode?.item(0)?.getElementsByClassName("e-list-text").item(0)?.textContent;
            //const listTextElement = textContentNode?.item(0)?.getElementsByClassName("e-list-text").item(0);

            //if (args?.isRoot && args?.name === listTextNode) {
            //    folderIcon?.setAttribute("style", "filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6) !important;");
            //}
        }
        setRepaintRequested(prev => prev + 1);
    };


    /**
     * Handles the failure event.
     * 
     * @param {FailureEventArgs} e - The event arguments for the failure.
     */
    const onFailure = (e: FailureEventArgs) => {

        try {
            //console.log("Application Error : " + e?.action + " :: " + JSON.stringify(e?.error));
            let error = e?.error as any;
            let errorCode = error?.code;
            let errorMessage = error?.message;
            if (errorCode && errorCode?.toString() === "404") {
                showToast("Connection Error", "File Manager", "e-toast-danger");
                setHasServerConnectionError(true);
            }
            else {
                if (errorMessage) {
                    if (e?.action === "copy" || e?.action === "move")
                        showToast(errorMessage, "File Manager", "e-toast-danger");

                    else

                        showToast(errorMessage, "File Manager", "e-toast-danger");
                }
                else {
                    showToast("Connection Error", "File Manager", "e-toast-danger");

                }
            }
        } catch (e) {
            console.log(e);
        }


        //document.getElementById('file_tb_upload').onclick = (e) => {
        //    e.stopPropagation();
        //};
    }

    //File Manager Core Function - Fired on each file selection change
    const onFileSelect = (args: FileSelectEventArgs) => {
        if (args) {
            var file = args?.fileDetails as FileDetailsObject;

            if (file) {
                dispatch(selectFile(file));
            }
            doRefreshPreviewer(prev => prev + 1);

            if (file?.isFile) {
                (async () => {
                    try {
                        await loadFileBytes(file.id, file.name, file?.fileProviderId ?? "");
                    } catch (error) {
                        console.info('Error loading file bytes:', error);
                    }
                })();
            }

            var selectedCount = fileManagerRef?.current?.selectedItems.length;
            if (selectedCount && selectedCount > 1) {
                var pdfCounts = 0;

                setCurrentSelectedFiles([]);
                var selectedFileItems = fileManagerRef?.current?.getSelectedFiles() as FileDetailsObject[];

                if (selectedFileItems) {
                    for (var i = 0; i < selectedFileItems.length; i++) {

                        var fileExt = getFileExtension(selectedFileItems?.at(i)?.name);
                        if (fileExt !== selectedFileItems?.at(i)?.name) {
                            if (fileExt?.toLowerCase() === ".pdf") {
                                pdfCounts++;
                                let filePath = selectedFileItems?.at(i)?.id;;

                                const newFileItem = new FileItem(selectedFileItems?.at(i)?.id, selectedFileItems?.at(i)?.name, '', filePath);
                                setCurrentSelectedFiles(prevFileItems => [
                                    ...prevFileItems,
                                    newFileItem,
                                ]);
                            }
                            else {
                            }
                        }
                        else {
                        }

                    }
                }
                if (pdfCounts === selectedCount) {
                    setFileActions(['Merge', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details'])
                }
                else {

                    if (displayVersion)
                        setFileActions(['Cut', 'Copy', 'Attach', '|', 'Delete', 'Download', '|']);
                    else
                        setFileActions(['Cut', 'Copy', '|', 'Delete', 'Download', '|']);

                }
            }
            else {
                setFileActions(['Preview', 'Edit', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details']);
                setCurrentSelectedFiles([]);
            }
        }

    }

    //File Manager Core Function
    const onFileSelection = (e: FileSelectionEventArgs) => {
        setRepaintRequested(prev => prev + 1);
    }

    const addFileAttachment = async () => {
        if (displayVersion === "select") {

            var allItems = fileManagerRef?.current?.getSelectedFiles() as FileDetailsObject[];
            let fileAttachmentRequests: FileAttachmentRequest[] = [];


            allItems.forEach(
                (a) => {
                    const newAttachedItem = new FileAttachmentRequest(a?.id, attachedModel, attachedModel, attachedModel);
                    fileAttachmentRequests.push(newAttachedItem);
                }
            );
            await fileAddAttachmentService

            const result = await fileAddAttachmentService(fileAttachmentRequests, fileSession);
            var data = result?.data;
            if (data) {
                showToast(allItems.length + " attachments added to object " + attachedModel + " successfully", "Attachments", "e-toast-success");
            }
            else {
                showToast(" Failed to add attachments to object " + attachedModel, "Attachments", "e-toast-warning");
            }
        }
    }

    //File Manager Core Function - On Menu Click
    const onMenuClick = (args: MenuClickEventArgs) => {

        if (args?.item?.text === 'Merge') {
            //alert('You have clicked custom menu item');
            setFileMergeDialogVisibility(true);
        }

        if (args?.item?.text === 'Attach') {

            addFileAttachment();
        }
        let encodedFilePath = "";
        let encodedFileName = "";

        let currentName = selectedFileReduced?.name;
        if (!currentName) {
            return;
        }

        if (args?.item?.text === 'Edit') {

            if (isDocumentProcessorSupported(currentName, ProcessorType.PDF, ActionType.Edit)) {
                if (fileManagerRef?.current?.selectedItems?.length === 1) {
                    //setQuickPDFEditDialogVisibility(true);
                    if (currentFileId) {
                        encodedFilePath = encodeURIComponent(currentFileId);
                        encodedFileName = encodeURIComponent(currentFileName);

                        window.open(`/pdf-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${selectedFileReduced?.fileProviderId ?? ""}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'pdfEditorTab');

                    }
                }
            }
            else
                if (isDocumentProcessorSupported(currentName, ProcessorType.Word, ActionType.Edit)) {
                    if (fileManagerRef?.current?.selectedItems?.length === 1) {
                        //setQuickPDFEditDialogVisibility(true);
                        if (currentFileId) {
                            const encodedFilePath = encodeURIComponent(currentFileId);
                            const encodedFileName = encodeURIComponent(currentFileName);
                            window.open(`/word-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${selectedFileReduced?.fileProviderId ?? ""}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'wordEditorTab');

                        }
                    }
                }
                else
                    if (isDocumentProcessorSupported(currentName, ProcessorType.Image, ActionType.Edit)) {
                        if (fileManagerRef?.current?.selectedItems?.length === 1) {
                            //setQuickPDFEditDialogVisibility(true);
                            if (args) {
                                args.cancel = true;
                            }

                            if (currentFileId) {
                                const encodedFilePath = encodeURIComponent(currentFileId);
                                const encodedFileName = encodeURIComponent(currentFileName);

                                window.open(`/image-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${selectedFileReduced?.fileProviderId ?? ""}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'imageEditorTab');
                            }
                        }
                    }
                    else
                        if (isDocumentProcessorSupported(currentName, ProcessorType.Excel, ActionType.Edit)) {
                            if (fileManagerRef?.current?.selectedItems?.length === 1) {
                                if (currentFileId) {
                                    const encodedFilePath = encodeURIComponent(currentFileId);
                                    const encodedFileName = encodeURIComponent(currentFileName);

                                    window.open(`/spreadsheet-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${selectedFileReduced?.fileProviderId ?? ""}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'spreadsheetEditorTab');
                                }
                            }
                        }
                        else {

                            showToast(`File type ${currentFileType} is not currently supported`, "Not Supported", "e-toast-info");
                        }
        }

        if (args?.item?.text === 'Preview' && selectedFileReduced?.isFile) {
            let defaultWindowSize = `width=${window.screen.availWidth},height=${window.screen.availHeight}`;
            if (isDocumentProcessorSupported(currentName, ProcessorType.PDF, ActionType.Preview)) {
                if (fileManagerRef?.current?.selectedItems?.length === 1) {
                    //setQuickPDFEditDialogVisibility(true);
                    if (currentFileId) {
                        //window.open(previewUrl, 'filePreviewWindow', 'width=800,height=600');
                        window.open(`/viewer?uuid=${encodeURIComponent(selectedFileReduced?.uuid)}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                    }
                }
            }
            else
                if (isDocumentProcessorSupported(currentName, ProcessorType.Word, ActionType.Preview)) {
                    if (fileManagerRef?.current?.selectedItems?.length === 1) {
                        if (currentFileId) {

                            window.open(`/viewer?uuid=${encodeURIComponent(selectedFileReduced?.uuid)}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else if (isDocumentProcessorSupported(currentName, ProcessorType.Text, ActionType.Preview)) {
                    if (fileManagerRef?.current?.selectedItems?.length === 1) {
                        if (currentFileId) {
                            window.open(`/viewer?uuid=${encodeURIComponent(selectedFileReduced?.uuid)}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else if (isDocumentProcessorSupported(currentName, ProcessorType.Image, ActionType.Preview)) {
                    if (fileManagerRef?.current?.selectedItems?.length === 1) {
                        if (args) {
                            args.cancel = true;
                        }

                        if (currentFileId) {

                            window.open(`/viewer?uuid=${encodeURIComponent(selectedFileReduced?.uuid)}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);

                        }
                    }
                }
                else if (isDocumentProcessorSupported(currentName, ProcessorType.Excel, ActionType.Preview)) {
                    if (fileManagerRef?.current?.selectedItems?.length === 1) {
                        if (currentFileId) {

                            window.open(`/viewer?uuid=${encodeURIComponent(selectedFileReduced?.uuid)}&sessionId=${fileSession.sessionId}&sessionHost=${fileSession.sessionHost}&sessionUid=${fileSession.sessionUid}&sessionB2C=${fileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else {

                    if (selectedFileReduced?.isFile) {
                        showToast(`File type ${currentFileType} is not currently supported`, "Not Supported", "e-toast-info");

                    }
                }
        }

    }



    //File Manager Core Function 
    const onMenuOpen = (args: MenuOpenEventArgs) => {

        if (args.menuType !== "layout") {
            //Repaints the icons
            args?.items?.forEach((node) => {
                if (node) {
                    if (node.text === "Merge") {
                        node.iconCss = 'e-icons e-merge-pdf';
                    }
                    if (node.text === "Edit") {
                        node.iconCss = 'e-icons e-edit-document';
                    }
                    if (node.text === "Attach") {
                        node.iconCss = 'e-icons e-add-attachment';
                    }
                    if (node.text === "Preview") {
                        node.iconCss = 'e-icons e-preview';
                    }
                }
            });

            if (args && args.fileDetails && args.fileDetails?.at(0)) {
                let currentItem = args?.fileDetails?.at(0) as unknown as FileDetailsObject;
                if (currentItem) {


                    if (selectedFileReduced) {
                        if (currentItem.name !== selectedFileReduced.name
                            || currentItem.filterPath !== selectedFileReduced.filterPath) {
                            dispatch(selectFile(currentItem));
                        }
                    }


                    //if (dirName?.isFile) {
                    //    dispatch(selectFile(dirName));
                    //    var path = dirName?.filterPath + dirName?.name;
                    //    loadFileBytes(path, dirName?.name, dirName?.fileProviderId ?? "");
                    //}
                }

            }

            //var dirName = args?.fileDetails?.at(0) as FileDetailsObject;
        }

    }

    const ontoolbarClick = (args: ToolbarClickEventArgs) => {
        //console.log("Action is :: " + args.item.text)

        if (args?.item?.text === "Attachments") {
            setFileAttachmentsDialogVisibility(true);
        }

        if (args?.item?.text === "Refresh") {
            handleFileRefresh();
        }

        if (args?.item?.text === "Roots") {

            selectParentFolder("Connections");
        }
    }


    const handleFileRefresh = () => {

        async function fetchSelectedFileContent() {
            showToast("Refreshing files in background...", "Refresh Files", "e-toast-info");
            let response = await refreshFileService(fileSession, selectedFileReduced?.fileProviderId);
            if (response) {
                if (!response.data.Success) {
                    showToast("File refresh failed", "File Refresh", "e-toast-danger");
                }
                else {
                    showToast("File refresh successfull", "File Rename", "e-toast-success");
                }
            }
        }
        fetchSelectedFileContent().then(
            () => {
                fileManagerRef?.current?.refresh();
            }
        );
    }

    //File Manager Core Function 
    const onBeforePopupOpen = (args: BeforePopupOpenCloseEventArgs) => {

        var currentName = selectedFileReduced?.name ?? fileManagerRef?.current?.selectedItems?.at(0);
        setTemporaryName(currentName ?? "");

        if (args.popupName === "Rename") {
            args.cancel = true;

            currentName = selectedFileReduced?.name;
            if (!currentName) {
                if (selectedFileReduced) {
                    currentName = selectedFileReduced.name;
                }
                if (!currentName && fileManagerRef && fileManagerRef.current) {
                    let currName = fileManagerRef.current.path.split('/').pop();
                    if (currName) {
                        currentName = currName;
                    }
                }
                if (!currentName) {
                    currentName = selectedFileReduced?.name ?? "";
                }
            }
            setTemporaryName(currentName);
            setFileRenameDialogVisibility(true);

        }

        if (args.popupName === "Create Folder") {
            args.cancel = true;
            setNewFolderName("");
            setFileCreateDialogVisibility(true);
        }


        if (args.popupName === "Delete") {
            args.cancel = true;
            if (fileManagerRef && fileManagerRef.current && selectedFileReduced) {
                currentName = selectedFileReduced.name;
                if (currentName) {
                    fileManagerRef.current.selectedItems.push(currentName);
                    setTemporaryName(currentName);
                }
                setFileDeleteDialogVisibility(true);

            }
        }

        if (args.popupName === "Upload") {
            args.cancel = true;
            fileManagerRef.current?.selectedItems.push(selectedFileReduced?.name ?? "");
            showToast("Uploading file(s) in the background", "File Upload", "e-toast-info");
        }

        if (args.popupName === "File Details") {
            args.cancel = true;
            fileManagerRef.current?.selectedItems.push(selectedFileReduced?.name ?? "");
            setFileDetailsDialogVisibility(true);

        }

        if (args.popupName === "Duplicate Items") {
            args.cancel = true;
            //fileManagerRef.current?.selectedItems.push(selectedFileReduced?.name ?? "");
            setFileDuplicateItemsDialogVisibility(true);
        }

        args.cancel = true;
    }

    const onPopupOpen = (args: PopupOpenCloseEventArgs) => {

    }

    const onBeforeDownload = (args: BeforeDownloadEventArgs) => {

    }
    //File Manager Core Function 
    const onFileOpen = (args: FileOpenEventArgs) => {

    }

    //File Manager Core Function 
    const onFileManagerClick = (args: any) => {

    }

    //File Manager Core Function 
    const onBeforePopupClose = (args: BeforePopupOpenCloseEventArgs) => {
    }

    //File Manager Core Function 
    const onPopupClose = (args: PopupOpenCloseEventArgs) => {
    }

    //File Manager Core Function 
    const onCreated = (args: Object) => {

    };

    const onCreated1 = (args: Object) => {
        // Ensure the button exists before attaching the dropdown
        //setTimeout(() => {
        //    const uploadBtnContainer = document.getElementById('file_tb_upload');
        //    if (uploadBtnContainer) {
        //        const drpDownBtn: DropDownButton = new DropDownButton(
        //            {
        //                items: items,
        //                select: (args) => {
        //                    const fmObj = fileManagerRef.current;
        //                    if (fmObj) {
        //                        if (args.item.text === 'Folder') {
        //                            fmObj.uploadSettings.directoryUpload = true;
        //                        } else {
        //                            fmObj.uploadSettings.directoryUpload = false;
        //                        }
        //                        setTimeout(() => {
        //                            const uploadBtn: HTMLElement = document.querySelector(
        //                                '.e-file-select-wrap button'
        //                            ) as HTMLElement;
        //                            if (uploadBtn) {
        //                                uploadBtn.click();
        //                            }
        //                        }, 100);
        //                    }
        //                }
        //            },
        //            '#file_tb_upload'
        //        );
        //    }
        //}, 100);
    };

    //Works when the split size has been changed - necessary to resize the pdf viewer so that it fits the content
    const onSplitChanged = (primarySize: string) => {
        if (selectedFileReduced
            && (selectedFileReduced?.type?.toLowerCase() === ".txt" || selectedFileReduced?.type?.toLowerCase() === ".pdf" || selectedFileReduced?.type?.toLowerCase() === ".docx") && showPreview) {
            doRefresh(prev => prev + 1);
            doRefreshPreviewer(prev => prev + 1);
        }
    };

    //Resets temporart data in the database
    const handleResetTempData = () => {

        async function fetchSelectedFileContent() {
            showToast("Resetting test data in background...", "Test Data", "e-toast-info");
        }
        fetchSelectedFileContent().then(
            () => {
                window.location.reload();
            }
        );
    }

    const handleCollapse = () => {
        setMinSecondarySize("35px");
        setIsExpanded(false);
    }

    const handleExpand = () => {
        setMinSecondarySize("30%");
        setIsExpanded(true);
    }

    const handleTemporaryNameChange = (e: any) => {
        setTemporaryName(e.target.value);
    }

    const handleNewFolderNameChange = (e: any) => {
        setNewFolderName(e.target.value);
    }

    //function to create a new folder
    const handleCreateNewFolder = () => {
        let filePath = '/';
        let fileName = newFolderName;
        if (fileManagerRef && fileManagerRef.current && fileManagerRef.current.path) {
            filePath = fileManagerRef.current.path;
        }

        if (!fileName) {
            showToast("Invalid filename.", "Folder Name", "e-toast-danger");
            return;
        }

        if (filePath) {
            if (fileManagerRef && fileManagerRef.current) {
                fileManagerRef.current.path = filePath;
                fileManagerRef.current.refreshFiles();
            }
        }
        createNewFolder(filePath, fileName);
        setFileCreateDialogVisibility(false);
    }



    const handleDuplicatePaste = () => {

        setFileDuplicateItemsDialogVisibility(false);
    }

    //function to reselect a folder, eg after rename
    const selectCurrentFolder = (path: string) => {
        let filePath = '/';
        if (path) {
            filePath = path;
        }

        if (fileManagerRef && fileManagerRef.current) {
            //fileManagerRef.current.clearSelection = [];
            fileManagerRef.current.path = filePath;
            fileManagerRef.current.refreshFiles();
        }
        //setWorkingDirectory(filePath);
        //doRefreshFiles(prev => prev + 1);
    }

    const refreshCurrentFolder = () => {
        var viewer = (document.getElementById("file") as any).ej2_instances[0];
        if (viewer) {
            var currentItem = viewer?.itemData[0]?.filterPath;
            if (!currentItem) {
                currentItem = "/";
            }
            else {
                currentItem = currentItem.replace(/\\+/g, "/");
            }

            setTimeout(function () {
                viewer.refreshLayout(function () {
                    let viewers = (document.getElementById("file") as any).ej2_instances[0];
                    if (viewers) {
                        viewers.path = currentItem;
                        viewers.refreshFiles();
                    }

                });
            }, 1000);

        }
    }


    //function to select parent folder, eg after deletion
    const selectParentFolder = (fileManagerPath: string) => {

        if (fileManagerPath) {
            debugger;
            var viewer = (document.getElementById("file") as any).ej2_instances[0];
            if (viewer) {
                var currentItem = viewer?.itemData[0]?.filterPath;
                if (!currentItem) {
                    currentItem = "/";
                }
                else {
                    currentItem = currentItem?.replace(/\\+/g, "/");
                }
                viewer.path = currentItem;
                let currentName = viewer.itemData[0]?.name;
                if (currentName) {
                    var fileExt = getFileExtension(currentName);
                    if (fileExt) {
                        viewer = (document.getElementById("file") as any).ej2_instances[0];
                        if (viewer) {

                            viewer?.refreshFiles(() => {
                                // selectCurrentFolder(fileManagerPath);
                            });
                        }
                    }
                }

            }

        }

    }

    //executed when creating a folder
    let isCreating = false;

    const createNewFolder = (path: string, name: string) => {
        if (isCreating) return;
        isCreating = true;
        async function fetchSelectedFileContent() {
            try {
                var currentItem = fileManagerRef?.current?.itemData[0] as unknown as FileDetailsObject;
                let fileProviderId = currentItem?.fileProviderId;
                if (fileProviderId) {
                    let response = await fileCreateFolderService(path, name, fileProviderId, fileSession);
                    if (response) {
                        if (!response.data.Success) {
                            showToast("Failed to create the Folder at the specified location", "Create Folder", "e-toast-danger");
                        } else {
                            showToast(name + " created", "Create Folder", "e-toast-success");
                        }
                    }
                    else {
                        showToast("Failed to create the Folder at the specified location", "Create Folder", "e-toast-danger");
                    }
                }

            } catch (e) {

            }
            finally {
                isCreating = false;
            }
        }
        fetchSelectedFileContent().then(
            () => {
                selectCurrentFolder(path);
            }
        );
    }

    //executed when renaming an item
    const renameFileItem = (path: string, name: string) => {
        if (calledArgs.some((args) => args.path === path && args.name === name)) return;
        setCalledArgs((prevCalledArgs) => [...prevCalledArgs, { path, name }]);

        async function fetchSelectedFileContent() {
            try {
                var currentItem = fileManagerRef?.current?.itemData[0] as unknown as FileDetailsObject;
                let fileProviderId = currentItem?.fileProviderId;
                if (fileProviderId) {
                    let response = await fileRenameService(path, name, fileProviderId, fileSession);
                    if (response) {
                        if (!response.data.Success) {
                            showToast(response.data.Message, "File Rename", "e-toast-danger");
                        } else {
                            showToast(name + " updated", "File Rename", "e-toast-success");
                        }
                    }
                }
            } catch (e) {
                console.log("Error while renaming" + e);
                showToast("An error occurred while renaming. Please try again", "File Rename", "e-toast-danger");

            } finally {
                setCalledArgs((prevCalledArgs) =>
                    prevCalledArgs.filter((args) => args.path !== path || args.name !== name)
                );
            }
        }

        fetchSelectedFileContent().then(
            () => {
                setTemporaryName("");
                var fileExt = getFileExtension(name);
                if (fileExt !== name) {
                    fileManagerRef?.current?.refreshFiles();
                }
                else {
                    selectParentFolder(path);
                }
            }
        );
    }



    //executed when deleting an item
    let isRemoving = false;

    const removeFileItem = (path: string) => {
        if (isRemoving) return;
        isRemoving = true;
        async function fetchSelectedFileContent() {
            try {

                var currentItem = fileManagerRef?.current?.itemData[0] as unknown as FileDetailsObject;
                let fileProviderId = currentItem?.id;
                if (fileProviderId) {
                    let response = await fileRemoveService(fileProviderId, currentItem?.id, fileSession);
                    if (response) {

                    }
                }


            } catch (e) {

            } finally {
                isRemoving = false;
            }
        }
        fetchSelectedFileContent().then(
            () => {
                selectParentFolder(path);
            }
        );
    }


    //Rename Files Function
    const handleFileRename = () => {
        if (selectedFileReduced?.isFile) {
            let fileRegex = /^[^<>:"/\\|?*]+\.[^<>:"/\\|?*]+$/;
            if (!fileRegex.test(temporaryName.toLowerCase())) {
                showToast("Invalid filename.", "File Rename", "e-toast-warning");
                return;
            }
        }
        else {
            let folderRegex = /^(?!.*\.[^.]+$)(?!.*[. ]$)(?!(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$)[^<>:"/\\|?*]+$/i;
            if (!folderRegex.test(temporaryName.toLowerCase())) {
                showToast("Invalid filename.", "File Rename", "e-toast-warning");
                return;
            }
        }

        showToast("Renaming File. Please wait...", "File Rename", "e-toast-info");
        if (selectedFileReduced?.isFile) {
            var fileExt = getFileExtension(selectedFileReduced?.name);
            var newFileExt = getFileExtension(temporaryName);
            if (newFileExt === fileExt && fileManagerRef && fileManagerRef.current) {
                let currentPath = selectedFileReduced?.id ?? (selectedFileReduced.filterPath + selectedFileReduced.name);
                if (currentPath) {
                    renameFileItem(currentPath, temporaryName);
                }
            }
            else {
                //no change
                if (newFileExt === temporaryName && fileManagerRef && fileManagerRef.current) {
                    let currentPath = selectedFileReduced?.id ?? (selectedFileReduced.filterPath + selectedFileReduced.name);
                    if (currentPath) {
                        renameFileItem(currentPath, `${temporaryName}.${fileExt}`);
                    }
                }
                else {
                    if (fileManagerRef && fileManagerRef.current) {
                        let currentPath = selectedFileReduced?.id ?? (selectedFileReduced.filterPath + selectedFileReduced.name);
                        if (currentPath) {
                            renameFileItem(currentPath, temporaryName);
                        }
                    }
                }
            }

        }
        else {
            if (fileManagerRef && fileManagerRef.current && selectedFileReduced) {
                let currentPath = selectedFileReduced?.id ?? (selectedFileReduced.filterPath + selectedFileReduced.name);
                if (currentPath) {
                    currentPath = currentPath.replace(/\/\/|\\\\/g, '/'); // replace any '//' or '\\' with '/'
                    if (currentPath.endsWith('/')) { // check if the path ends with '/'
                        currentPath = currentPath.slice(0, -1); // remove the last character
                    }

                    renameFileItem(currentPath, temporaryName);
                } else {
                    var fileName = fileManagerRef.current.selectedItems[0];
                    if (fileName) {
                        currentPath = selectedFileReduced?.id ?? fileManagerRef.current.path + fileName;
                        renameFileItem(currentPath, temporaryName);
                    }

                }
            }

        }

        setFileRenameDialogVisibility(false);
    }

    //Delete Files Function
    const handleFileDelete = () => {
        showToast("Deletion started. Please wait!", "Delete", "e-toast-info");
        let pendingFiles: string[] = [];
        var currentTempFiles = fileManagerRef?.current?.selectedItems;
        if (currentTempFiles) {
            currentTempFiles.forEach((a) => {
                if (a)
                    pendingFiles.push(a);
            })
        }

        if (pendingFiles) {
            if (pendingFiles.length > 0) {
                if (pendingFiles.length > 1) {
                    if (fileManagerRef && fileManagerRef.current) {
                        fileManagerRef.current.deleteFiles(pendingFiles);
                    }
                }
                else {
                    pendingFiles.forEach((a) => {
                        if (selectedFileReduced) {
                            let currentPath = (selectedFileReduced.filterPath + a);
                            if (currentPath) {
                                removeFileItem(currentPath);
                            }
                        }
                    });
                }
            }
        }

        setFileDeleteDialogVisibility(false);
    }

    //Shows Toast messages
    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 5000,
            cssClass: cssClass
        });
    };

    //Available file types
    const isTypeAvailable = (fileType: string) => {

        return isDocumentSupportedForAnyProcessor(fileType, ActionType.Preview);
    };

    const reloadPage = () => {
        window.location.reload();
    };

    function onBeforeSend(args: any) {


        args.ajaxSettings.beforeSend = function (args: any) {
            args.httpRequest.setRequestHeader('session-id', fileSession.sessionId);
            args.httpRequest.setRequestHeader('session-host', fileSession.sessionHost);
            args.httpRequest.setRequestHeader('session-uid', fileSession.sessionUid);
            args.httpRequest.setRequestHeader('session-b2c', fileSession.sessionB2C);
        };

    }

    const getBase64String = (fileId: string) => {
        const fileItem = currentCache.find(fileItem => fileItem.id === fileId);
        return fileItem?.base64String || "";
    };

    const handleRemoveAllAttachedItems = async () => {
        const selectedIds = attachedItems;
        let fileAttachmentRequests: RemoveAttachedConnectionsRequest[] = [];

        selectedIds.forEach(
            (a: any) => {
                const newAttachedItem = new RemoveAttachedConnectionsRequest(a.id, attachedModel);
                fileAttachmentRequests.push(newAttachedItem);
            }
        );


        const result = await fileRemoveSelectedAttachedService(fileAttachmentRequests, fileSession);
        if (result.data) {
            if (result.data.Success) {
                showToast("Items were removed successfully", "Attached Items", "e-toast-success");
                setFileAttachmentsDialogVisibility(false)
            } else {
                showToast("An error occurred. Please refresh and try again", "Attached Document", "e-toast-danger");
            }
        }
    }

    const getDisplayTitle = () => {
        if (selectedFileReduced) {

            if (!selectedFileReduced.isFile && !selectedFileReduced.isRoot) {
                return "Folder";
            }
            if (!selectedFileReduced.isFile && selectedFileReduced.isRoot) {
                return "Root";
            }
        }

        return "File";
    }

    const isFileMatchingSelecting = (file: FileDetailsObject) => {

        var selectedId = fileManagerRef?.current?.selectedItems?.at(0);

        if (file && selectedId && file.id === selectedId) {
            return true;
        }
        else {
            if (!file?.isFile) {
                if (fileManagerRef?.current?.selectedItems?.length === 0)
                    return true;
               
            }
        }

        return false;
    }

    const handleRemoveSelectedAttachedItems = async () => {
        // Get a reference to the ListView component
        const listViewInstance = (document.getElementById('list') as any)?.ej2_instances[0];

        const selectedItems = listViewInstance.getSelectedItems().data;


        const selectedIds = selectedItems.map((item: AttachedItem) => item.id);

        let fileAttachmentRequests: RemoveAttachedConnectionsRequest[] = [];


        selectedIds.forEach(
            (a: any) => {
                const newAttachedItem = new RemoveAttachedConnectionsRequest(a, attachedModel);
                fileAttachmentRequests.push(newAttachedItem);
            }
        );


        const result = await fileRemoveSelectedAttachedService(fileAttachmentRequests, fileSession);
        if (result.data) {
            if (result.data.Success) {
                showToast("Items were removed successfully", "Attached Items", "e-toast-success");
                setFileAttachmentsDialogVisibility(false)
            } else {
                showToast("An error occurred. Please refresh and try again", "Attached Document", "e-toast-danger");
            }
        }
    }


    return (
        <div style={{ height: `95vh` }} id="content-app" className="e-card">
            <Split initialPrimarySize='100%'
                onSplitChanged={onSplitChanged}
                minSecondarySize={minSecondarySize}
                minPrimarySize='40%'
                splitterSize="2px"
                defaultSplitterColors={{ hover: '#694b61', color: '#dee2e6', drag: '#694b61' }}
            >
                <div >
                    {
                        (!hasServerConnectionError) ?
                            <FileManagerComponent id="file" ref={fileManagerRef}
                                e-animate={false}
                                view="Details"
                                height="95vh"

                                allowDragAndDrop={false}
                                contextMenuSettings={{
                                    file: fileActions,
                                    folder: folderActions,
                                    layout: layoutActions,
                                }}
                                toolbarItems={toolbarItems}
                                ajaxSettings={{
                                    downloadUrl: SERVER_URL +
                                        `api/FileProvider/ProviderDownload?provider=${currentFileProvider}&id=${fileSession.sessionId}`,
                                    getImageUrl: SERVER_URL +
                                        `api/FileProvider/ProviderGetImage?provider=${currentFileProvider}`,
                                    uploadUrl: SERVER_URL +
                                        `api/FileProvider/ProviderUpload?provider=${currentFileProvider}`,
                                    url: SERVER_URL +
                                        `api/FileProvider/ProviderFileOperations?provider=${currentFileProvider}`
                                }}
                                success={(e) => onSuccess(e)}

                                failure={(e) => onFailure(e)}
                                fileSelect={(e) => onFileSelect(e)}
                                fileLoad={(e) => onFileLoad(e)}
                                fileSelection={(e) => onFileSelection(e)}
                                fileOpen={(e) => onFileOpen(e)}
                                menuClick={(e) => onMenuClick(e)}
                                menuOpen={(e) => onMenuOpen(e)}
                                popupOpen={(e) => onPopupOpen(e)}
                                beforeDownload={(e) => onBeforeDownload(e)}
                                beforePopupClose={(e) => onBeforePopupClose(e)}
                                beforePopupOpen={(e) => onBeforePopupOpen(e)}
                                toolbarClick={(e) => ontoolbarClick(e)}
                                popupClose={(e) => onPopupClose(e)}
                                beforeSend={(e) => onBeforeSend(e)}
                                created={(e) => onCreated(e)}
                                onClick={(e) => onFileManagerClick(e)}
                                uploadSettings={{
                                    maxFileSize: uploadFileSizeLimit,
                                    directoryUpload: false
                                }}

                            >
                                <Inject services={
                                    displayVersion === "select" ? [NavigationPane, DetailsView, Toolbar] : [NavigationPane, DetailsView, Toolbar]
                                } />

                            </FileManagerComponent>
                            :

                            <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '95vh',
                                    width: '100%'
                                }}>
                                    <div>
                                        Failed to connect to the server. Please <a href="#" onClick={reloadPage}>try</a> again.
                                    </div>

                                </div>

                            </>


                    }


                </div>

                <div>
                    <>
                        {(showPreview) &&

                            <>

                                <TabComponent id='defaultTab'>
                                    <div className="e-toolbar-items e-tbar-pos" style={{ display: 'flex', flexDirection: 'row' }}>

                                        {
                                            isExpanded ?
                                                <>
                                                    <div className="e-toolbar-item">
                                                        <ButtonComponent
                                                            iconCss='e-icons e-collapse'
                                                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib tabbed-button"
                                                            iconPosition='Left'
                                                            title='Collapse'
                                                            onClick={handleCollapse}
                                                        >
                                                        </ButtonComponent>
                                                    </div>
                                                    {/* <div className="e-toolbar-item">
                                                        <ButtonComponent
                                                            iconCss='e-icons e-refresh'
                                                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib tabbed-button"
                                                            iconPosition='Left'
                                                            title='Reset Database'
                                                            onClick={handleResetTempData}
                                                        >
                                                            Reset Database
                                                        </ButtonComponent>
                                                    </div> */}
                                                    {
                                                        (displayVersion === "select") &&
                                                        <div className="e-toolbar-item">
                                                            <ButtonComponent
                                                                iconCss='e-icons e-refresh'
                                                                className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib tabbed-button"
                                                                iconPosition='Left'
                                                                title='Apply Attachment'
                                                                onClick={handleResetTempData}
                                                            >
                                                                Apply Attachment
                                                            </ButtonComponent>
                                                        </div>
                                                    }
                                                </> :

                                                <>
                                                    <div className="e-toolbar-item">
                                                        <ButtonComponent
                                                            iconCss='e-icons e-expand'
                                                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib tabbed-button"
                                                            iconPosition='Left'
                                                            title='Expand'
                                                            onClick={handleExpand}
                                                        >
                                                        </ButtonComponent>
                                                    </div>
                                                </>
                                        }
                                        <div className="e-tab-header" >


                                            <div> Properties </div>
                                            <div> Preview </div>

                                        </div>
                                    </div>
                                    <div className="e-content">
                                        <div>
                                            <div className="sidebar-parent">
                                                <div className="sidebar-child" >

                                                    <div className="e-card"
                                                        style={{
                                                            backgroundColor: '#3f3f3f',
                                                            height: '95vh',

                                                        }}>

                                                        {
                                                        (selectedFileReduced && isFileMatchingSelecting(selectedFileReduced)) &&
                                                            <FileProperties FileDetailsObject={selectedFileReduced} FolderItemClassResponse={folderItemClassResponse} FileSession={fileSession}
                                                                onRefreshFunction={function (): void {
                                                                    refreshFileManager();
                                                                }} />

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="sidebar-parent">
                                                <div className="sidebar-child" >

                                                    <div className="e-card"
                                                        style={{
                                                            backgroundColor: '#3f3f3f',
                                                            height: '90vh',
                                                            visibility: `${(selectedFileReduced && selectedFileReduced.isFile
                                                                && isDocumentSupportedForAnyProcessor(selectedFileReduced?.name, ActionType.Preview)) ?
                                                                `visible` : `hidden`}`
                                                        }}>
                                                        {
                                                            (selectedFileReduced &&
                                                                currentCache.find(
                                                                    fileItem =>
                                                                        fileItem.id ===
                                                                        selectedFileReduced.id)?.base64String && selectedFileReduced?.isFile && currentFileName
                                                                && (
                                                                    isDocumentProcessorSupported(selectedFileReduced?.name, ProcessorType.Image, ActionType.Preview)
                                                                )) &&

                                                            <img
                                                                src={
                                                                    (selectedFileReduced &&
                                                                        getImageSrc(selectedFileReduced?.name ?? "",
                                                                            getBase64String(selectedFileReduced?.id)
                                                                        )) ?? ""
                                                                }
                                                                alt={selectedFileReduced?.name}
                                                                style={{
                                                                    height: 'auto',
                                                                    width: '100%',
                                                                    aspectRatio: '1/1'
                                                                }}
                                                            />
                                                        }


                                                        {
                                                            (selectedFileReduced
                                                                && isDocumentProcessorSupported(selectedFileReduced?.name, ProcessorType.PDF, ActionType.Preview)
                                                                && selectedFileReduced?.isFile && currentFileName) &&

                                                            <PdfJsPreviewer pdfPath={encodeURIComponent(currentFileId)}
                                                                fileId={encodeURIComponent(currentFileId)}
                                                                fileName={encodeURIComponent(currentFileName)}
                                                                fileProvider={selectedFileReduced?.fileProviderId ?? ""}
                                                                fileSession={fileSession} />
                                                        }


                                                        {
                                                            (selectedFileReduced
                                                                && isDocumentProcessorSupported(selectedFileReduced?.name, ProcessorType.Text, ActionType.Preview)
                                                                && selectedFileReduced?.isFile && currentFileName) &&

                                                            <div style={{
                                                                height: '90vh',
                                                                width: '100%',
                                                                overflowY: 'auto',
                                                                marginTop: '5px'
                                                            }} >

                                                                <FileManagerRichTextEditorPreview
                                                                    html={currentCache.find(fileItem => fileItem.id === selectedFileReduced.id)?.base64String} />
                                                            </div>
                                                        }

                                                        {
                                                            (selectedFileReduced
                                                                && (isDocumentProcessorSupported(selectedFileReduced?.name, ProcessorType.Excel, ActionType.Preview))
                                                                && selectedFileReduced?.isFile && currentFileName) &&


                                                            <FileManagerSpreadsheetPreviewer
                                                                base64FileString={currentCache.find(fileItem => fileItem.id === selectedFileReduced.id)?.base64String}
                                                                fileName={currentFileName}
                                                                fileSession={fileSession} />
                                                        }

                                                        {
                                                            (selectedFileReduced
                                                                && (
                                                                    isDocumentProcessorSupported(selectedFileReduced?.name, ProcessorType.Word, ActionType.Preview)
                                                                )
                                                                && selectedFileReduced?.isFile && currentFileName) &&

                                                            <div style={{
                                                                'height': '90vh',
                                                                'width': '100%',
                                                                'overflowY': 'auto'
                                                            }} >
                                                                <FileManagerDocumentPreviewer
                                                                    filePath={currentFileId}
                                                                    fileName={currentFileName}
                                                                    refresh={refreshPreviewer}
                                                                    fileSession={fileSession} />
                                                            </div>
                                                        }



                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </TabComponent>

                            </>
                        }
                    </>

                </div>
            </Split >

            {/*PREVIEW FILES DIALOG*/}

            < FileManagerStyledDialog
                visible={filePreviewDialogVisibility}
                size={"lg"}
                target='#content-app'
                title={currentFileName}
                isSaving={false}
                canResize={true}
                showSaveButton={true}
                setOpen={
                    function (value: boolean): void {
                        if (showPdfFileDialog) {
                            setShowPdfFileDialog(false);
                            fileManagerRef?.current?.refreshFiles();
                        }
                        setFilePreviewDialogVisibility(value);

                    }
                }

                saveChanges={
                    function (value: boolean): void {
                        if (value && showPdfFileDialog) {
                            setSavePdfChanges(prev => prev + 1);
                            alert("Saved changes");
                        }
                    }
                }

                resizeChildren={
                    function (): void {
                        doResize(prev => prev + 1);
                    }
                }
            >
                <div>
                    {filePreviewDialogVisibility &&
                        <>
                            <FileManagerPreviewerDialog fileId={selectedFileReduced?.uuid} fileSession={fileSession} />
                        </>
                    }




                </div>
            </FileManagerStyledDialog >
            {/*PDF DIALOG*/}

            < FileManagerStyledDialog
                visible={fileOpenDialogVisibility}
                size={"lg"}
                target='#content-app'
                title={currentFileName}
                isSaving={false}
                canResize={true}
                showSaveButton={true}
                setOpen={
                    function (value: boolean): void {
                        if (showPdfFileDialog) {
                            setShowPdfFileDialog(false);
                            fileManagerRef?.current?.refreshFiles();
                        }
                        setFileOpenDialogVisibility(value);

                    }
                }

                saveChanges={
                    function (value: boolean): void {
                        if (value && showPdfFileDialog) {
                            setSavePdfChanges(prev => prev + 1);
                            alert("Saved changes");
                        }
                    }
                }

                resizeChildren={
                    function (): void {
                        doResize(prev => prev + 1);
                    }
                }
            >
                <div>
                    {showPdfFileDialog &&
                        <FileManagerPDFViewer pdfViewerRef={pdfViewerRef}
                            pdfPath={currentFileId} pdfName={''}
                            fileId={currentFileId}
                            serviceUrl={PDF_SERVICE_URL}
                            saveChanges={savePdfChanges}
                            refresh={resize}
                            setOpen={function (value: boolean): void {
                                setShowPdfFileDialog(value);
                                setFileOpenDialogVisibility(value);
                                fileManagerRef.current?.refreshFiles();
                            }} />
                    }

                    {(showTextFileDialog && selectedFileReduced) &&
                        <div style={{ height: '80vh' }}>
                            <FileManagerRichTextEditorPreview
                                html={(currentCache.find(
                                    fileItem =>
                                        fileItem.path === (selectedFileReduced.filterPath + selectedFileReduced.name))?.base64String)} />
                        </div>
                    }

                    {(showImageFileDialog && selectedFileReduced) &&
                        <div style={{ height: '80vh' }}>
                            <img
                                src={"data:image/png;base64," + (currentCache.find(
                                    fileItem =>
                                        fileItem.path === (selectedFileReduced.filterPath + selectedFileReduced.name))?.base64String)}
                                alt={selectedFileReduced?.name}
                                style={{
                                    'height': '80vh',
                                    'width': '100%'
                                }} />
                        </div>
                    }



                </div>
            </FileManagerStyledDialog >

            {/*MERGE FILES DIALOG*/}

            < FileManagerStyledDialog visible={fileMergeDialogVisibility}
                size={"sm"}
                target='#content-app'
                isSaving={false}
                title={"Merge PDF Files"}
                allowDragging={false}
                showSaveButton={false}
                canResize={true}
                setOpen={
                    function (value: boolean): void {
                        setFileMergeDialogVisibility(value);
                    }
                }

                saveChanges={
                    async function (value: boolean): Promise<void> {

                    }
                }
            >
                <>
                    {
                        fileMergeDialogVisibility &&

                        <FileManagerMergePdfFiles

                            files={currentSelectedFiles}
                            setOpen={function (value: boolean): void {
                                fileManagerRef?.current?.refreshFiles();
                                fileManagerRef?.current?.clearSelection();
                                //selectParentFolder("current");
                                setFileMergeDialogVisibility(value);

                            }}
                            fileProvider={selectedFileReduced?.fileProviderId ?? ""} fileSession={fileSession} />
                    }
                </>

            </FileManagerStyledDialog >

            {/*DELETE FILES DIALOG*/}

            < FileManagerStyledDialog visible={fileDeleteDialogVisibility}
                size={"sxx"}
                target='#content-app'
                isSaving={false}
                title={"Delete"}
                canResize={false}
                allowDragging={false}
                showSaveButton={false}
                setOpen={
                    function (value: boolean): void {
                        setFileDeleteDialogVisibility(value);
                    }
                }

                saveChanges={
                    async function (value: boolean): Promise<void> {

                    }
                }

                buttons={
                    [
                        {
                            buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                            click: () => setFileDeleteDialogVisibility(false)
                        },
                        {
                            buttonModel: { content: 'Yes', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                            click: () => handleFileDelete()
                        }
                    ]}

            >
                <>

                    <div style={{
                        padding: '5px',
                        marginRight: '10px',
                        display: 'flex',
                        height: 'auto',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }} >
                        <div>
                            Are you sure you want to delete the {getDisplayTitle()}?
                        </div>

                    </div>

                </>

            </FileManagerStyledDialog >

            {/*FILE RENAME DIALOG*/}

            < FileManagerStyledDialog visible={fileRenameDialogVisibility}
                size={"sxx"}
                target='#content-app'
                isSaving={false}
                canResize={false}
                allowDragging={false}
                title={"Rename"}
                showSaveButton={false}

                setOpen={
                    function (value: boolean): void {
                        setFileRenameDialogVisibility(value);
                    }
                }

                saveChanges={
                    async function (value: boolean): Promise<void> {

                    }
                }

                buttons={
                    [
                        {
                            buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                            click: () => setFileRenameDialogVisibility(false)
                        },
                        {
                            buttonModel: { content: 'Save', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                            click: () => handleFileRename()
                        }
                    ]}

            >
                <>
                    <div style={{ padding: '5px', marginRight: '10px' }} >
                        <input
                            className="e-input"
                            type="text"
                            value={temporaryName}
                            placeholder="New File Name"
                            onChange={handleTemporaryNameChange}
                        />
                    </div>

                </>

            </FileManagerStyledDialog >

            {/*FILE CREATE DIALOG*/}

            < FileManagerStyledDialog visible={fileCreateDialogVisibility}
                size={"sxx"}
                target='#content-app'
                isSaving={false}
                canResize={false}
                title={"Create Folder"}
                showSaveButton={false}
                setOpen={
                    function (value: boolean): void {
                        setFileCreateDialogVisibility(value);
                    }
                }

                saveChanges={
                    async function (value: boolean): Promise<void> {

                    }
                }

                buttons={
                    [
                        {
                            buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                            click: () => setFileCreateDialogVisibility(false)
                        },
                        {
                            buttonModel: { content: 'Save', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                            click: () => handleCreateNewFolder()
                        }
                    ]}

            >
                <>
                    <div style={{ padding: '5px', marginRight: '10px' }} >
                        <input
                            className="e-input"
                            type="text"
                            value={newFolderName}
                            placeholder="Folder Name"
                            onChange={handleNewFolderNameChange}
                        />
                    </div>

                </>

            </FileManagerStyledDialog >

            {/*FILE DETAILS DIALOG*/}

            < FileManagerStyledDialog visible={fileDetailsDialogVisibility}
                size={"sx"}
                target='#content-app'
                isSaving={false}
                title={getDisplayTitle() + ' Details'}
                allowDragging={false}
                canResize={false}
                showSaveButton={false}
                setOpen={
                    function (value: boolean): void {
                        setFileDetailsDialogVisibility(value);
                    }
                }

                saveChanges={
                    async function (value: boolean): Promise<void> {

                    }
                }

                buttons={
                    [
                        {
                            buttonModel: { content: 'Ok', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                            click: () => setFileDetailsDialogVisibility(false)
                        }
                    ]}

            >
                <>
                    {
                        selectedFileReduced?.isRoot === true
                            ?
                            <div style={{ padding: '5px', marginRight: '10px' }} >

                                <div style={{ padding: '5px', marginRight: '10px' }} >
                                    <div className={"fileDetailItem"}>
                                        Display Name: {selectedFileReduced?.name}
                                    </div>
                                    <div className={"fileDetailItem"}>
                                        Root Type: {selectedFileReduced?.fileProviderType}
                                    </div>
                                    <div className={"fileDetailItem"}>
                                        Root Name: {selectedFileReduced?.fileProviderName}
                                    </div>
                                </div>

                            </div>

                            :
                            <div style={{ padding: '5px', marginRight: '10px' }} >

                                <div style={{ padding: '5px', marginRight: '10px' }} >
                                    {
                                        selectedFileReduced?.isFile === true
                                            ?
                                            <div className={"fileDetailItem"}>
                                                File Name: {selectedFileReduced?.name}
                                            </div>
                                            :
                                            <div className={"fileDetailItem"}>
                                                Folder Name: {selectedFileReduced?.name}
                                            </div>
                                    }

                                    <div className={"fileDetailItem"}>
                                        Date Created: {getFormattedDate(selectedFileReduced?.dateCreated)}
                                    </div>
                                    <div className={"fileDetailItem"}>
                                        Date Modified: {getFormattedDate(selectedFileReduced?.dateModified)}
                                    </div>

                                </div>

                            </div>

                    }


                </>

            </FileManagerStyledDialog >


            {/*FILE DUPLICATE DIALOG*/}

            < FileManagerStyledDialog visible={fileDuplicateItemsDialogVisibility}
                size={"sxx"}
                target='#content-app'
                isSaving={false}
                canResize={false}
                title={"File/Folder Exists"}
                showSaveButton={false}
                setOpen={
                    function (value: boolean): void {
                        setFileDuplicateItemsDialogVisibility(value);
                    }
                }

                saveChanges={
                    async function (value: boolean): Promise<void> {

                    }
                }

                buttons={
                    [

                        {
                            buttonModel: { content: 'Ok', iconCss: 'e-icons e-ok-check-mark', cssClass: 'e-flat toolbar-button', isPrimary: true },
                            click: () => setFileDuplicateItemsDialogVisibility(false)
                        }
                    ]}

            >
                <>
                    <div style={{ padding: '5px', marginRight: '10px' }} >
                        <div className={"fileDetailItem"}>
                            The file(s) already exists in the target location.
                        </div>
                    </div>

                </>

            </FileManagerStyledDialog>



            {/*ATTACHED FILES DIALOG*/}

            <FileManagerStyledDialog visible={fileAttachmentsDialogVisibility}
                size={"md"}
                target='#content-app'
                isSaving={false}
                title={"Attached Files"}
                allowDragging={false}
                showSaveButton={false}
                canResize={true}
                setOpen={function (value: boolean): void {
                    setFileAttachmentsDialogVisibility(value);
                }}

                saveChanges={async function (value: boolean): Promise<void> {

                }}

                buttons={[
                    {
                        buttonModel: { content: 'Cancel', iconCss: 'e-icons e-close-dialog', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                        click: () => setFileAttachmentsDialogVisibility(false)
                    },
                    {
                        buttonModel: { content: 'Remove All', iconCss: 'e-icons e-delete', isPrimary: false, cssClass: 'e-flat toolbar-button' },
                        click: () => handleRemoveAllAttachedItems()
                    },
                    {
                        buttonModel: { content: 'Remove Selected', iconCss: 'e-icons e-delete', cssClass: 'e-flat toolbar-button', isPrimary: true },
                        click: () => handleRemoveSelectedAttachedItems()
                    }
                ]}
            >
                <>
                    {
                        true &&

                        <>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <ListViewComponent id="list" dataSource={attachedItems as any} fields={attachedItemFields} showCheckBox={true} checkBoxPosition="Right" />
                            </div>

                        </>
                    }
                </>

            </FileManagerStyledDialog >


        </div >
    )
}
